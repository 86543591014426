/* Header CSS */

.company-logo-wrapper{
    max-width: -webkit-fill-available;
    height: 72px;
    fill: #ffffff;
    margin: 0px 0px 20px 0px;
}

.company-logo-wrapper > img {
    width: 168px;
    height: 31px;
    margin: 21px 180px 20px 0px;
}

/* *************************************************************************************************** */


/* Footer CSS */

.footer-wrapper{
    max-width: -webkit-fill-available;
    height: 320px;
    padding: 5px;
    padding-left: 16px;
}

.footer-inner-wrapper{
    display: inherit;
    padding-top: 25px;
    margin-bottom: 33px;
    height: 100px;
}

.footer-company-logo{
    padding-top: 15px;
}

.footer-company-logo > img{
    width: 230px;
    height: 42px;
    margin-bottom: 33px;
}

.footer-copyright-message{
    width: 263px;
    height: 18px;
    font-family: sans-serif;
    font-size: 14px;
    color: #63666a;
    text-decoration: none solid rgb(99, 102, 106);
    line-height: 18px;
    text-align: right;
}

/* **************************************************************************************************** */


/*New User Login CSS*/


.new-user-wrapper{
    margin: 0px;
    padding-left: 11px;
}

.first-time-visit-message{
    margin: 0px 12px 20px 0px;
    width: inherit;
    height: 76px;
    font-family: sans-serif;
    font-size: 32px;
    color: #212121;
    text-decoration: none solid rgb(33, 33, 33);
    line-height: 38px;
}

.first-time-visit-description{
    width: inherit;
    height: 104px;
    font-family: sans-serif;
    font-size: 18px;
    color: #212121;
    text-decoration: none solid rgb(33, 33, 33);
    line-height: 26px;
    margin: 0px 12px 20px 0px;
}

.first-time-visit-warning{
    width: -webkit-fill-available;
    height: 40px;
    font-family: sans-serif;
    font-size: 14px;
    color: #212121;
    text-decoration: none solid rgb(33, 33, 33);
    line-height: 20px;
    margin: 0px 12px 20px 0px;
}

.language-selection-wrapper{
    width: inherit;
    height: auto;
    background-color: #f4f4f4;
    padding: 30px;
    margin-bottom: 30px;
}

.language-selection-title{
    width: inherit;
    height: 84px;
    font-family: sans-serif;
    font-size: 20px;
    color: #212121;
    text-decoration: none solid rgb(33, 33, 33);
    line-height: 28px;
    margin-bottom: 20px;
}

.language-radio-button{
    display: flex;
    margin-bottom: 20px;
}

.lang-radio  input[type=radio]{
    width: 22px;
    height: 24px;
    margin-right: 5px;
    margin-left: 5px;
    accent-color: #000099;
    border-width: 10px;
    position: relative;
    left: -8px;
    top: -4px;
}

.lang-radio{
    width: 26px;
    height: 26px;
    border-radius: 100%;
    border: 5px solid #747474;
    float: left;
    margin-right: 4px;
}


.language-name{
    width: 235px;
    height: 36px;
    font-family: sans-serif;
    font-size: 16px;
    color: #212121;
    text-decoration: none solid rgb(33, 33, 33);
    line-height: 24px;
}

.save-button{
    width: 278px;
    height: 50px;
    border-radius: 2px;
    background-color: #000099;
    background-size: cover;
    font-family: sans-serif;
    font-size: 14px;
    color: #ffffff;
    text-decoration: none solid rgb(255, 255, 255);
    text-align: center;
    box-shadow: 0px 1px 2px rgba(0,0,0,0.2);
    border-width: 0;
}
/* *********************************************************************************************** */


/* Covid Pass CSS */

.covid-pass-heading{
    width: 100%;
    height: 36px;
    background-color: #212121;
    color: white;
    padding: 0;
}

.covid-pass-heading-title{
    width: 92px;
    height: 18px;
    font-family: sans-serif;
    font-size: 18px;
    text-decoration: none solid rgb(255, 255, 255);
    text-align: center;
    margin: 6px 0px 12px 12px;
    float: left;
}

.covid-pass-languages{
    height: 24px;
    width: 75px;
    font-size: 14px;
    font-family: sans-serif;
    background-color: inherit;
    color: white;
    text-decoration: none solid rgb(255, 255, 255);
    border: 0;
    margin: 6px 6px 6px 0px;
    float: right;
    font-weight: bold;
}

.vaccine-green{
    width: 100%;
    height: auto;
    background-color: #6cc24a;
    padding: 20px 12px 15px 12px;
}

.vaccine-amber{
    width: 100%;
    height: auto;
    background-color: #ff8200;
    padding: 20px 12px 15px 12px;
}

.vaccine-grey{
    width: 100%;
    height: auto;
    background-color: #d8d8d8;
    padding: 20px 12px 15px 12px;
}

.covid-pass-results-heading-vaccine-supported{
    margin-bottom: 20px;
    width: -webkit-fill-available;
    height: auto;
    font-family: sans-serif;
    font-size: 32px;
    color: #ffffff;
    text-decoration: none solid rgb(255, 255, 255);
    line-height: 38px;
    text-align: center;
    font-weight: bold;
}

.covid-pass-results-heading-vaccine-not-supported{
    margin-bottom: 20px;
    width: -webkit-fill-available;
    height: auto;
    font-family: sans-serif;
    font-size: 32px;
    color: #212121;
    text-decoration: none solid rgb(33, 33, 33);
    line-height: 38px;
    text-align: center;
    font-weight: bold;
}

.vaccine-details{
    width: -webkit-fill-available;
    height: auto;
    background-color: #ffffff;
    padding-top: 30px;
    margin-bottom: 15px;
}

.date-heading{
    width: -webkit-fill-available;
    height: 26px;
    font-family: sans-serif;
    font-size: 16px;
    color: #63666a;
    text-decoration: none solid rgb(99, 102, 106);
    text-align: center;
    font-weight: bold;
    margin-left: 1px;
    margin-right: 1px;
}

.todays-date{
    width: -webkit-fill-available;
    height: 100px;
    font-family: sans-serif;
    font-size: 100px;
    color: #212121;
    text-decoration: none solid rgb(33, 33, 33);
    letter-spacing: -3px;
    text-align: center;
    font-weight: bold;
    position: relative;
    top: -30px;
    margin-bottom: 30px;
}

.vaccine-status{
    width: -webkit-fill-available;
    height: auto;
    text-align: -webkit-center;
    position: relative;
    top: -20px;
}

.icon-circle{
    width: 126px;
    height: 126px;
    position: relative;
    left: 35px;
    z-index: 1;
}

.icon-mark{
    width: 72px;
    height: 72px;
    z-index: 1;
    position: relative;
    left: -63px;
}

.vaccine-status-message-green{
    width: 303px;
    height: auto;
    font-family: sans-serif;
    font-size: 26px;
    color: #6cc24a;
    text-decoration: none solid rgb(108, 194, 74);
    line-height: 26px;
    text-align: center;
    font-weight: bold;
    margin-left: 16px;
    margin-right: 16px;
    padding-left: 30px;
    padding-right: 30px;
}

.vaccine-status-message-amber{
    width: 303px;
    height: auto;
    font-family: sans-serif;
    font-size: 22px;
    color: #ff8200;
    text-decoration: none solid rgb(108, 194, 74);
    line-height: 26px;
    text-align: center;
    font-weight: bold;
    margin-left: 16px;
    margin-right: 16px;
    padding-left: 30px;
    padding-right: 30px;
}

.vaccine-not-supported{
    width: -webkit-fill-available;
    height: 158px;
    position: relative;
    top: -20px;
    text-align: center;
}

.vaccine-not-supported-title{
    width: -webkit-fill-available;
    height: 26px;
    font-family: sans-serif;
    font-size: 26px;
    color: #ca0018;
    text-decoration: none solid rgb(202, 0, 24);
    line-height: 26px;
    text-align: center;
    margin: 0px 16px 10px 16px;
    font-weight: bold;
}

.vaccine-not-supported-message{
    width: -webkit-fill-available;
    margin: 0px 26px 0px 26px;
    height: auto;
    font-family: sans-serif;
    font-size: 16px;
    color: #212121;
    text-decoration: none solid rgb(33, 33, 33);
    line-height: 24px;
    text-align: center;
    padding-bottom: 51px;
}

.vaccine-not-supported > img {
    width: 158px;
    height: 158px;
}

.additional-guidance-wrapper{
    width: -webkit-fill-available;
    height: auto;
    background-color: #ffffff;
    padding: 22px;
}

.additional-title-heading{
    width: -webkit-fill-available;
    height: 24px;
    font-family: sans-serif;
    font-size: 16px;
    color: #63666a;
    text-decoration: none solid rgb(99, 102, 106);
    line-height: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.additional-title-desctiption{
    width: -webkit-fill-available;
    height: auto;
    font-family: sans-serif;
    font-size: 16px;
    color: #212121;
    text-decoration: none solid rgb(33, 33, 33);
    line-height: 24px;
    margin-bottom: 20px;
}

.guidance-button{
    width: -webkit-fill-available;
    height: 42px;
    border-radius: 2px;
    background-color: #000099;
    background-size: cover;
    font-family: sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    text-decoration: none solid rgb(255, 255, 255);
    text-align: center;
    box-shadow: 0px 1px 2px rgb(0 0 0 / 20%);
    border: 0;
}

.person-name{
    padding: 24px 38px 36px 38px;
    width: -webkit-fill-available;
    height: auto;
    font-family: sans-serif;
    font-size: 44px;
    color: #212121;
    text-decoration: none solid rgb(33, 33, 33);
    line-height: 40px;
    text-align: center;
    font-weight: bold;
}
/* *********************************************************************************************** */


/* Loading Spinner */

.full-screen{
    height: 100vh;
    padding-top: 48vh;
}

/* *************************************************************************************************** */

/* Error screen */

.error-wrapper{
    padding: 0px 36px 36px 36px;
    position: relative;
    top: -10px;
}

.error-heading{
    font-size: 30px;
    line-height: 30px;
    color: #212121;
    font-family: sans-serif;
    margin-bottom: 16px;
    font-weight: bold;
}

.error-message{
    font-size: 14px;
    font-family: sans-serif;
}